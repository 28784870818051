import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import CountryList from "../components/countryList";
import postStyles from "../styles/post.module.scss";

const CountriesPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark;

  const countries = new Map();

  edges.forEach(edge => {
    const node = edge.node;

    const { country, category, langKey } = node.frontmatter;
    const { path } = node.fields;

    if (!countries.get(country)) {
      countries.set(country, new Map());
    }

    let tempCountry = countries.get(country);
    tempCountry.set(`${category}.${langKey}`, {
      ...node.frontmatter,
      fields: { path: path },
    });
    countries.set(country, tempCountry);
  });

  const countryLists = [];
  for (const country of countries) {
    countryLists.push(
      <CountryList
        key={country[0]}
        list={country[1]}
        countryName={country[0]}
      />
    );
  }

  return (
    <Layout>
      <SEO linkImage="cover-home.jpg" />
      <div className={postStyles.postContainer}>
        <div className={postStyles.post}>
          <h2>All Countries:</h2>
          {countryLists}
        </div>
      </div>
    </Layout>
  );
};

export default CountriesPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "law" } } },
      sort: {
        fields: [frontmatter___country],
        order: ASC
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            country
            type
            category
            langKey
          }
          fields {
            path
          }
        }
      }
    }
  }
`;
