import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import languageHelper from "../lib/languageHelper";

class CountryList extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    const entries = [];
    this.props.list.forEach(element => {
      entries.push(element);
    });

    const listEntries = entries.map(element => {
      return (
        <li key={element.fields.path}>
          <small style={{ marginRight: "5px" }}>
            {languageHelper.getLanguage(element.langKey)}:
          </small>
          <Link to={element.fields.path}>{element.title}</Link>
        </li>
      );
    });

    return (
      <div>
        <h3>{this.props.countryName}</h3>
        <ul>{listEntries}</ul>
      </div>
    );
  }
}

CountryList.propTypes = {
  countryName: PropTypes.string,
};

export default CountryList;
