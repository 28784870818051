const languages = [
  // shorthand, full string (English)
  ["da", "Danish"],
  ["de", "German"],
  ["en", "English"],
];

class LanguageHelper {
  /**
   * @description: returns language code by language
   * @param {string} language
   * @returns {string} languageCode
   */
  getShortHand(language) {
    return this.languageToCode.get(language);
  }

  /**
   * @description: returns language by language code
   * @param {string} languageCode
   * @returns {string} language
   */
  getLanguage(languageCode) {
    return this.codeToLanguage.get(languageCode);
  }

  constructor() {
    this.languages = languages;
    this.codeToLanguage = new Map();
    this.languageToCode = new Map();
    this.languages.forEach(element => {
      this.codeToLanguage.set(element[0], element[1]);
      this.languageToCode.set(element[1], element[0]);
    });
  }
}

export default new LanguageHelper();
